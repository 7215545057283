var menuJSON = function(){
    this.target=$('#menu-content');
    this.lastRequest='';
    var screens=$('#menu-content div.panel:not(.left)');

    this.active_screen=false;
    if(screens.length>0){
        this.active_screen=$(screens[0]);
    }
    this.return=$('#menu .return');
    this.return_obj=this.return.data('obj');
    this.return_display=this.return.hasClass('display');
    if(arguments.length==0){
        this.get('root');
    }
    else{
        this.get(arguments[0]);
    }

    
}

menuJSON.prototype={
    constructor: menuJSON,
    get:function(){
        var path = arguments[0];
        this.lastRequest=path;
        //$.getJSON('/assets/json/menu.json','c=MENU_C&a=get_menu&path='+path,function(res){
        $.getJSON('index.php','c=MENU_C&a=get_menu&path='+path,function(res){
            if(res.length>0){
                //création du panel :
                var panel=$('<div class="panel right"></div>');
                var menu =$('<ul></ul>');
                for(var i=0;i<res.length;i++){
                    //console.log(res[i]);
                    var men=$('<li></li>');
                    var html = $('<a></a>');
                    html.html(res[i].content);
                    men.append(html);
                    men.click(function(){
                        $('#menu ul li.active').each(function(){
                            $(this).removeClass('active');
                        });
                        $(this).addClass('active');
                    });
                    if(res[i].sub){
                        men.data('path',res[i].path);
                        men.data('menu',this);
                        men.addClass('sub');
                        men.click(function(e){
                            new menuJSON($(this).data('path'));
                        });
                    }
                    else{
                        if(res[i].page!=''){
                            men.data('page',res[i].page);
                            men.click(function(){
                                new LoadPage({data: $(this).data('page'),'transition':true,complemente_title:' '});
                                //new LoadPage({data: $(this).data('page'),'transition':true,url:'test.html',return:true});
                            });
                        }
                        else{
                            men.data('action',res[i].action);
                            men.click(function(e){
                                window.exec($(this).data('action'));
                            });
                        }
                    }
                    menu.append(men);
                }

                
                panel.append(menu);
                this.target.append(panel);
                
                this.current=panel;
                this._end(); 
            }
        }.bind(this));
    },
    _end:function(){
        if(this.active_screen){
            setTimeout(function(){
                this.active_screen.addClass('left');
            }.bind(this),50);
            this.return.addClass('display');
            this.return.off('click');
            this.return.data('obj',this);
            this.return.click(function(){
                var that = $(this).data('obj');
                that.active_screen.removeClass('left');
                that.current.addClass('right');
                setTimeout(function(){this.current.remove()}.bind(that),550);
                $(this).data('obj',that.return_obj);
                if(!that.return_display){
                    $(this).removeClass('display');
                }
            })
        }
        setTimeout(function(){
            this.current.removeClass('right');
        }.bind(this),50);
    }
};