$( document ).ready(function(){
    if($("#sub_pref")){
        $("#sub_pref").load('index.php?c=USER_MENU_C');
        //$('#sub_pref').get('index.php?c=USER_MENU_C')
    }
});
function logout(){
    $.ajax({url:'index.php?logout=1',success:function(){
        location.reload();    
    }})
    
}