function load_tab(tab_container){
    // creation du menu
    var menu = $('<div class="tab_menu"></div>');
    var container = $('<div class="tab_container"></div>');
    // analyse des tabs :
    var childs = $(tab_container).children('.tab');
    childs.each(function(){
        var tab = $(this);
        container.append($(this));
        var menu_tab = $('<div class="btn"></div>');
        menu_tab.data('tab',this);
        //creation titre et icon :
        var label = $('<span class="label">'+tab.attr('tablabel')+'</span>');
        menu_tab.append(label)
        var img = $('<img class="'+tab.attr('tabclass')+'"  src="data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" />');
        menu_tab.append(img);
        menu_tab.click(function(){
            // on passe les autres bouton inactif
            $(this).parent('.tab_menu').children('.btn').each(function(){
                $(this).removeClass('active');
            });
            $(this).addClass('active');
            // gestion des panels :
            $($(this).parent().parent().children('.tab_container')[0]).children('.tab').each(function(){
                $(this).removeClass('active');
            });
            $($(this).data('tab')).addClass('active');

            $('.msDropdown').each(function(){
                var ddm = $(this).msDropdown().data('dd');
                ddm.destroy();
                $(this).msDropdown();
                
            });
         });
         menu.append(menu_tab);
         
    });
    tab_container.append(container);
    tab_container.append(menu);
    //activation premier plan :
    $(menu.children('.btn')[0]).trigger('click');
}