var count_loading = 0;
function add_loading(){
    count_loading++;
    if(count_loading==1){
        $('.transferring').addClass('display');
        setTimeout(function(){$('.transferring').addClass('show');},5);
    }
    //update_loading();
}
function remove_loading(){
    count_loading--;
    if(count_loading==0){
        $('.transferring').removeClass('show');
        setTimeout(function(){$('.transferring').removeClass('display');},27);
    }
    //update_loading();
}
function update_loading(){
    if(count_loading > 0){
        $('.transferring').addClass('display');
    }
    else{
        $('.transferring').removeClass('display');   
    }
}
var LoadPage = function(options={}){
    this.options = options;
    this.old_return = null;
    this.old_screen = null;
    this.final_target = null;
    this.active_screen = null;
    this.init();
}
var load_page_block=false;
LoadPage.prototype={
    constructor: LoadPage,
    default_options : {
        element: '#page .content',
        element_txt: '',
        url: 'index.php',
        return: false,
        id: '',
        return_reset: true,
        return_elmt: '#page .return-container .return',
        transition: false,
        replace: false,
        block_loading: false,
        method: 'get',
        data:{}
    },
    init:function(){
        this.options = $.extend({}, this.default_options,this.options);
        if(typeof(this.options.element)=='string'){
            if(this.options.element=='#page .content'){
                this.options.element_txt='#page .content';
            }
            if(this.options.element_txt=='#page .content' && load_page_block){
                this.options.block_loading=true;
            }
            this.options.element = $($(this.options.element)[0]);
        }
        if(typeof(this.options.return_elmt)=='string'){
            this.options.return_elmt = $($(this.options.return_elmt)[0]);
        }
        if(this.options.transition){
            if(this.options.return_reset){
                this.options.element.children('.panel.old_panel').remove();
            }
            this.options.finaltarget = this.options.element;
            this.options.newId = Foundation.GetYoDigits(32);
            this.options.element = $("<div class='panel right' cid='"+this.options.id+"' id='"+this.options.newId+"' />");
        }
        this._makeRequest();
    },
    _makeRequest:function(){
        var toto = true;
        if(this.options.block_loading && load_page_block){
            toto=confirm('Are you sure you don\'t want to save the form?');
        }
        if(toto){
            if(this.options.block_loading && load_page_block){
                load_page_block=false;
            }
            add_loading();
            $.ajax({
                url: this.options.url,
                cache: false,
                method: this.options.method,
                data: this.options.data,
                success:function(data){
                    if(!this.options.replace){
                        this.options.element.html(this.options.element.html()+data);
                    }
                    else{
                        this.options.element.html(data);
                    }
                    this._end();
                }.bind(this)
            })
        }
    },
    _end : function(){
        remove_loading();
        if(this.options.transition){
            var active_screen = this.options.finaltarget.find("div.panel.crt_panel");
            if(typeof active_screen.attr('id') == 'undefined'){
                this.options.oldId = Foundation.GetYoDigits(32);
                active_screen.attr('id',this.options.oldId);
            }
            else{
                this.options.oldId = active_screen.attr('id');
            }
            this.options.active_screen=active_screen;
            this.options.old_screen=active_screen;
            this.options.finaltarget.append(this.options.element);
            if(this.options.return_reset || !this.options.return_elmt.hasClass('display')){
                this.options.return_display=false;
                this.options.return_elmt.data('load_obj',null);
                this.options.return_elmt.removeClass('display');
            }
            else{
                this.options.return_display=this.options.return_elmt.hasClass('display');
                if(this.options.return_display){
                    this.options.return_obj = this.options.return_elmt.data('load_obj');
                }
            }
            //this.options.old_screen.addClass('left');
            setTimeout(function(){this.options.old_screen.addClass('left');}.bind(this),10);
            setTimeout(function(){this.options.element.removeClass('right');}.bind(this),10);
            active_screen.addClass('old_panel').removeClass('crt_panel');
            this.options.element.addClass('crt_panel');
            if(this.options.return){
                this.options.return_elmt.off('click');
                this.options.return_elmt.addClass('display');
                this.options.return_elmt.data('load_obj',this);
                this.options.return_elmt.click(function(){
                    var that = $(this).data('load_obj');
                    var current_screen = $('#'+that.options.newId);
                    var old_screen = $('#'+that.options.oldId);
                    
                    var toto = true;
                    if(that.options.element_txt=='#page .content' && load_page_block){
                        toto=confirm('Are you sure you don\'t want to save the form?');
                    }
                    if(toto){
                        if(that.options.elment_txt=='#page .content' && load_page_block){
                            load_page_block=false;
                        }

                        old_screen.removeClass('left').addClass('crt_panel').removeClass('old_panel');
                        current_screen.removeClass('crt_panel').addClass('to_destroy').addClass('right');
                        
                        
                        if(!that.options.return_display){
                            that.options.return_elmt.removeClass('display');
                            that.options.return_elmt.off('click');
                        }
                        else{
                            that.options.return_elmt.data('load_obj',that.options.return_obj);
                        }
                        setTimeout(function(){that.options.element.remove();}.bind(this),550);
                    }
                });
                if(active_screen){
                    active_screen.addClass('left');
                }
            }
            else{
                this.options.active_screen.addClass('left');
                setTimeout(function(){this.options.active_screen.remove();}.bind(this),550);
            }
        }
    }

};