function load_sub(tab_container){
    // creation du menu
    var menu = $('<div class="sub_menu"></div>');
    var container = $('<div class="sub_container"></div>');
    // analyse des tabs :
    var childs = tab_container.children('.sub');
    childs.each(function(){
        var tab = $(this);
        container.append(tab);
        var menu_tab = $('<div class="subBtn"></div>');
         menu_tab.data('sub',tab);
         //creation titre et icon :
         var labTxt=tab.attr('sublabel');
         if(labTxt=='0_'){
            labTxt = "Aucune categorie";
         }
         var sublabClass=tab.attr('sublabel_class');
         if(sublabClass!=''){
            menu_tab.addClass(sublabClass);
         }
         var label = $('<span class="label">'+labTxt+'</span>');
         menu_tab.append(label);
         menu_tab.click(function(){
            // on passe les autres bouton inactif
            $(this).parent('.sub_menu').children('.subBtn').each(function(){
                var el = this;
                $(el).removeClass('active');
            });
            $(this).addClass('active');
            // gestion des panels :
            $($(this).parent().parent().children('.sub_container')[0]).children('.sub').each(function(){
                $(this).removeClass('active');
            });
            $($(this).data('sub')).addClass('active');
            $('.msDropdown').each(function(){
                var ddm = $(this).msDropdown().data('dd');
                ddm.destroy();
                $(this).msDropdown({visibleRows:5});
                
            });
         });
         menu.append(menu_tab);
         
         
    });
    tab_container.append(menu);
    tab_container.append(container);
    //activation premier plan :
    $(menu.children('.subBtn')[0]).trigger('click');
}