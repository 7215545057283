function sdc_file_selector(input,img=null) {
    window.KCFinder = {};
    window.KCFinder.callBack = function(url) {
        // Actions with url parameter here
        window.KCFinder = null;
        input.value=url;
        if(img!=null){
            img.src=url;
        }
    };
    var strWindowFeatures = "menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=no,resizable=no,dependent,width=800,height=620,left=0,top=0";
    window.open('external/kcfinder/browse.php?opener=custom&type=images', 'kcfinder_single',strWindowFeatures);
}